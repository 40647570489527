<template>
  <div class="modal" v-if="visible">
    <div class="modal-overlay" @click="closeModal"></div>
    <form id="callMePlease" class="modal-content" @submit.prevent="submitForm" @click.stop>
      <h2 class="modal__title">
        {{ title }}
      </h2>
      <div class="modal__input">
        <input
          type="text"
          v-model="name"
          name="name"
          placeholder="Как вас зовут?"
          required
          pattern="[A-Za-zА-Яа-яЁё]+"
        />
      </div>
      <!-- <div class="modal__input">
        <input
          type="text"
          v-model="phoneNumber"
          name="phoneNumber"
          placeholder="Ваш номер телефона"
          required
          v-mask="'+7 (###) ###-##-##'"
        />
      </div> -->
      <div class="form__group">
            <div class="modal__input">
              <label for="countryCode">Код страны:</label>
              <select v-model="countryCode" name="countryCode" required>
                <option value="7">+7</option>
                <option value="375">+375</option>
              </select>
            </div>
            <div class="modal__input" v-if="countryCode == 7">
              <input
                type="text"
                v-model="phoneNumber"
                name="phoneNumber"
                placeholder="Ваш номер телефона"
                required
                v-mask="'(###) ###-##-##'"
              />
            </div>
            <div class="modal__input" v-if="countryCode == 375">
              <input
                type="text"
                v-model="phoneNumber"
                name="phoneNumber"
                placeholder="Ваш номер телефона"
                required
                v-mask="'(##) ###-##-##'"
              />
            </div>
          </div>
      <button class="modal__btn btn" type="submit">{{ btn }}</button>
      <div class="modal__police">
        Нажимая на кнопку "{{ btn }}" я соглашаюсь с
        <a href="#">политикой конфиденциальности</a>
      </div>
      <span class="modal-close" @click="closeModal">&times;</span>
    </form>
  </div>
  <!-- Thank you popup template -->
    <div v-if="thankYouVisible" class="thank-you-popup">
      <div class="thank-you-overlay" @click="closeThankYouPopup"></div>
      <div class="thank-you-content">
        <h2 class="thank-you-title">Спасибо! <br>
          Ваша заявка получена. <br>
          В ближайшее время менеджер свяжется с вами.</h2>
        <span class="thank-you-close" @click="closeThankYouPopup">&times;</span>
      </div>
    </div>
</template>

<script>
import axios from "axios";
import { VueMaskDirective } from "vue-the-mask";

export default {
  name: "Modal",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: String,
    btn: String,
  },
  data() {
    return {
      name: "",
      phoneNumber: "",
      thankYouVisible: false,
      countryCode: "7",
    };
  },
  directives: {
    mask: VueMaskDirective,
  },
  mounted() {
    if (this.visible) {
      this.disableScroll();
      document.addEventListener("click", this.handleOutsideClick);
    }
  },

  beforeUnmount() {
    this.enableScroll();
    document.removeEventListener("click", this.handleOutsideClick);
  },
  watch: {
    visible(value) {
      if (value) {
        this.disableScroll();
      } else {
        this.enableScroll();
      }
    },
  },
  beforeUnmount() {
    this.enableScroll();
  },
  methods: {
    handleOutsideClick(event) {
      const modalContent = this.$refs.modalContent;
      if (modalContent && !modalContent.contains(event.target)) {
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("close");
    },
    disableScroll() {
      document.body.style.overflow = "hidden";
    },
    enableScroll() {
      document.body.style.overflow = "";
    },
    submitForm() {
      axios
        .post("/api/feedback_2.php", {
          name: this.name,
          countryCode: this.countryCode,
          phoneNumber: this.phoneNumber,
        })
        .then((response) => {
          console.log(response.data);
          this.name = "";
          this.phoneNumber = "";
          this.countryCode = "7";
          this.closeModal();
          this.showThankYouPopup();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showThankYouPopup() {
      this.thankYouVisible = true;
    },
    closeThankYouPopup() {
      this.thankYouVisible = false;
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Change the opacity value to adjust the shading */
  z-index: -1;
}

.modal-content {
  max-width: 600px;
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

/* Add any additional styles for the modal content here */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
  color: #555;
}

.modal__title {
  font-size: 36px;
  text-align: center;
  margin-bottom: 16px;
}

input {
  border-radius: 50px;
  width: 100%;
  border: 1px solid #333333;
  padding: 16px 12px;
}
.modal__input:not(:first-child) {
  margin-top: 8px;
}
.modal__btn {
  margin-top: 16px;
  width: 100%;
}
.modal__police {
  color: #333333;
  margin-top: 16px;
  text-align: center;
}
.modal-open {
  overflow: hidden;
}

.thank-you-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.thank-you-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.thank-you-content {
  max-width: 400px;
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.thank-you-title {
  font-size: 24px;
  text-align: center;
}

.thank-you-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #555;
}

.modal__input select {
  border-radius: 50px;
  width: 120px; /* Adjusted width to account for label */
  border: 1px solid #efefef;
  padding: 16px 12px;
  padding-left: 24px; 
}

.modal__input label {
  display: none;
}

.form__group {
  display: flex;
  align-content: center;
  margin-top: 8px;
  gap: 5px
}

.form__group .modal__input {
  margin: 0;
}

.form__group .modal__input:first-child {
  width: 120px;
}

.form__group .modal__input:not(:first-child) {
  width: calc(100% - 120px);
}
</style>
