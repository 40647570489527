<template>
  <section class="application" id="nationsCall">
    <h3 class="application__steps">
      Заполните форму → Подберём вакансии → Поможем трудоустроить
    </h3>
    <div class="application__wrapper wrapper">
      <form class="form" @submit.prevent="submitForm">
        <div class="form__wrapper">
          <h2 class="form__title">{{ title }}</h2>
          <div class="form__input">
            <input
              type="text"
              v-model="name"
              name="name"
              placeholder="Как вас зовут?"
              required
              pattern="[A-Za-zА-Яа-яЁё\s-]+"
              @input="validateName"
            />
          </div>
          <div class="form__group">
            <div class="form__input">
              <label for="countryCode">Код страны:</label>
              <select v-model="countryCode" name="countryCode" required>
                <option value="7">+7</option>
                <option value="375">+375</option>
              </select>
            </div>
            <div class="form__input" v-if="countryCode == 7">
              <input
                type="text"
                v-model="phoneNumber"
                name="phoneNumber"
                placeholder="Ваш номер телефона"
                required
                v-mask="'(###) ###-##-##'"
              />
            </div>
            <div class="form__input" v-if="countryCode == 375">
              <input
                type="text"
                v-model="phoneNumber"
                name="phoneNumber"
                placeholder="Ваш номер телефона"
                required
                v-mask="'(##) ###-##-##'"
              />
            </div>
          </div>
          <div class="form__input">
            <input
              type="text"
              v-model="age"
              name="age"
              placeholder="Сколько вам лет?"
              required
              v-mask="'##.##.####'"
            />
          </div>
          <div class="form__input">
            <label for="citizenship" v-if="!citizenship"
              >Выберите гражданство:</label
            >
            <select v-model="citizenship" name="citizenship" required>
              <option value="" disabled selected>Выберите гражданство</option>
              <option value="RF">Российская Федерация (РФ)</option>
              <option value="RB">Республика Беларусь (РБ)</option>
            </select>
          </div>
          <button class="form__btn btn" type="submit">{{ btn }}</button>
          <div class="form__police">
            Нажимая на кнопку "{{ btn }}" я соглашаюсь с
            <a href="#">политикой конфиденциальности</a>
          </div>
        </div>
      </form>
      <div v-if="formSubmitted" class="success-message thank-you-popup">
        <div class="thank-you-overlay" @click="closeThankYouPopup"></div>
        <div class="thank-you-content">
          <h2 class="thank-you-title">
            Спасибо! <br/>
            Ваша заявка получена. <br />
            В ближайшее время менеджер свяжется с вами.
          </h2>
          <span class="thank-you-close" @click="closeThankYouPopup">×</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { VueMaskDirective } from "vue-the-mask";

export default {
  data() {
    return {
      title: "Оставить заявку",
      btn: "Помогите найти работу",
      name: "",
      phoneNumber: "",
      age: "",
      citizenship: "",
      formSubmitted: false,
      thankYouVisible: false,
      countryCode: "7",
    };
  },
  directives: {
    mask: VueMaskDirective,
  },
  methods: {
    submitForm() {
      axios
        .post("/api/feedback.php", {
          name: this.name,
          phoneNumber: this.phoneNumber,
          countryCode: this.countryCode,
          age: this.age,
          citizenship: this.citizenship,
        })
        .then((response) => {
          console.log(response.data);
          this.name = "";
          this.phoneNumber = "";
          this.countryCode = "7";
          this.age = "";
          this.citizenship = "";
          this.formSubmitted = true;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    validateName() {
      // Remove non-letter characters from the input value
      this.name = this.name.replace(/[^A-Za-zА-Яа-яЁё\s-]/g, "");
    },
    closeThankYouPopup() {
      this.formSubmitted = false;
    },
  },
};
</script>

<style scoped>
.form__input label {
  display: none;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #555;
  transition: opacity 0.3s ease; /* Added transition for smooth hide/show */
}

.form__input label[aria-hidden="true"] {
  opacity: 0;
}

.form__input input,
.form__input select {
  border-radius: 50px;
  width: calc(100% - 24px); /* Adjusted width to account for label */
  border: 1px solid #efefef;
  padding: 16px 12px;
  padding-left: 24px; /* Space for the label */
}

.form__group {
  display: flex;
  align-content: center;
  margin-top: 8px;
}

.form__group .form__input {
  margin: 0;
}

.form__group .form__input:first-child {
  width: 120px;
}

.form__group .form__input:not(:first-child) {
  width: calc(100% - 120px);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Change the opacity value to adjust the shading */
  z-index: -1;
}

.modal-content {
  max-width: 600px;
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

/* Add any additional styles for the modal content here */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
  color: #555;
}

.modal__title {
  font-size: 36px;
  text-align: center;
  margin-bottom: 16px;
}

input {
  border-radius: 50px;
  width: 100%;
  border: 1px solid #333333;
  padding: 16px 12px;
}
.modal__input:not(:first-child) {
  margin-top: 8px;
}
.modal__btn {
  margin-top: 16px;
  width: 100%;
}
.modal__police {
  color: #333333;
  margin-top: 16px;
  text-align: center;
}
.modal-open {
  overflow: hidden;
}

.thank-you-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.thank-you-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.thank-you-content {
  max-width: 400px;
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.thank-you-title {
  font-size: 24px;
  text-align: center;
}

.thank-you-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #555;
}
/* 
.success-message {
  text-align: center;
}

.success-title {
  font-size: 24px;
  margin-top: 16px;
} */
</style>

