<template>
  <section class="benefits" id="benefits">
    <div class="benefits__wrapper wrapper">
      <ul class="benefits__list-2">
        <li>Гражданство<br>РФ и РБ</li>
        <li>Возраст<br>от 18 до 50 лет</li>
        <li>Строго<br>без судимостей</li>
      </ul>
      <h2 class="benefits__title">
        Премии и бонусы
      </h2>
      <div class="benefits__item">
          <img class="benefits__img" src="img/bonus.png" alt="">
        </div>
      <button class="benefits__btn btn" @click="openModal">Подробнее</button>
      <h2 class="benefits__title benefits__title-2">
        Наши преимущества
      </h2>
      <div class="benefits__list">
        <div class="benefits__item" v-for="(item, idx) in items" :key="idx">
          <img class="benefits__img" :src="item.src" alt="">
          <p class="benefits__text">{{ item.text }}</p>
        </div>
      </div>
      
    </div>
    <MoreBlock
      :visible="modalVisible"
      :title="modalTitle"
      :img_2="modalImg"
      :text="modalText"
      @close="closeModal"
    />
  </section>
</template>

<script>
import MoreBlock from "@/ui/MoreBlock.vue";

export default {
  components: {
    MoreBlock,
  },
  data() {
    return {
      modalVisible: false,
      modalImg: "/img/money.jpg",
      modalTitle: "Премии и бонусы",
      modalText: `<p><strong>💰Денежные премии для наших сотрудников💰</strong></p>
      <p>🚉 Компенсация денег за дорогу к нам, при сохранении билета - до 2000₽.
Даже, если у Вас нет билета и вы приехали к нам с Москвы или области, мы возместим вам 1000₽.</p>

<p><strong>🤘 Премия за повторную вахту:</strong></p>
      <p>- 35 смен - 10.000₽</p>
      <p>- 45 смен - 15.000₽</p>

<p>👦👧 Премия за приведённого друга (подругу) - 10000 ₽</p>

<p>🚀 Премия 3000 ₽ самому производительному сотруднику на каждой площадке Яндекс и Озон - каждые 2 недели</p>
`,
      items: [
        {
          src: "/img/money.png",
          text: "Высокая заработная плата",
        },
        {
          src: "/img/time.png",
          text: "Своевременный расчет",
        },
        {
          src: "/img/free.png",
          text: "Бесплатное проживание",
        },
        {
          src: "/img/lunch.png",
          text: "Комплексные обеды",
        },
        {
          src: "/img/manager.png",
          text: "Личный менеджер",
        },
        {
          src: "/img/phone.png",
          text: "Круглосуточная поддержка",
        },
      ],
    };
  },
  methods: {
    openModal() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
  },
};
</script>

<style lang="scss">
  .more__text {
    p {
      margin-top: 8px;
      text-align: left;
    }
  }
</style>