<template>
  <header class="header" :class="{ 'header-fixed': scrolled }">
    <div class="header__wrapper wrapper">
      <div class="header__logo">
        <p>Stockroom<br>
        <span>logistics</span></p>
        <p class="header__logo-text">Работа вахтой</p>

      </div>
      <div class="header__anchorns">
        <a 
          href="#vacancy" 
          @click.prevent="scrollToAnchor" 
          class="header__btn btn"
        >
          Вакансии
        </a>
        <a 
          href="#contacts" 
          @click.prevent="scrollToAnchor" 
          class="header__btn btn"
        >
          Контакты
        </a>
      </div>
      <div class="header__contacts">
        <a href="tel: +7(499)404-17-86" class="header__contacts-tel">+7 (499) 404-17-86</a>
        <div class="header__contacts-social">
          <a href="https://vk.com/stocklog" target="_blank" class="header__icon icon-vk"></a>
          <a href="https://wa.me/79154197519" target="_blank" class="header__icon icon-whatsapp"></a>
          <a href="https://t.me/Yulia210117" target="_blank" class="header__icon icon-telegram"></a>
        </div>
        <button class="header__btn btn header__btn-call" v-if="scrolled" @click="openModal">Перезвоните мне</button>
      </div>
      <modal :visible="showModal" @close="closeModal" :title='title' :btn='btn'></modal>
    </div>
  </header>
  <!-- Add your modal code here -->
  <div class="modal" v-if="showModal">
    <!-- Modal content -->
  </div>
</template>
<script>
import Modal from "../ui/ModalBlock.vue";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      scrolled: false,
      showModal: false,
      title:
        "Оставьте заявку, мы перезвоним и ответим на все ваши вопросы",
      btn: "Перезвоните мне",
    };
  },
  mounted() {
      window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      const threshold = 100;
      this.scrolled = scrollPosition > threshold;
    },
    scrollToAnchor(event) {
      const target = event.target.getAttribute("href");
      const element = document.querySelector(target);

      if (element) {
        event.preventDefault();
        window.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
      }
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>
<style lang="scss">
.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: background-color, border 0.3s ease;
  background-color: #fff !important;
  border-bottom: 1px solid #e2e2e2;

  .header__logo,
  .header__contacts {
    transition: color 0.3s ease;
    color: #333333;
  }
  .header__logo-text {
    font-size: 16px;
    text-align: left;
    display: flex;
    padding: 5px 10px;
    background: #ed6929;
    color: #fff;
  }
}
.header__logo-text {
    font-size: 16px;
    text-align: left;
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    background: #fff;
    color: #000;
    border-radius: 32px;
    margin-top: 10px;
  }
</style>