<template>
  <div class="more" v-if="visible">
    <div class="more-overlay" @click="closeModal"></div>
    <div class="more-content" @click.stop>
      <swiper v-if="swiper"
        :modules="modules"
        :slides-per-view="1"
        :space-between="50"
        navigation
        :pagination="{ clickable: true }"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide v-for="(src, key) in img" :key="key">
          <img :src="src"  alt="" />
        </swiper-slide>
      </swiper>
      <img v-else :src="img_2" alt="">
      <h2 class="more__title">
        {{ title }}
      </h2>
      <div class="more__text" v-html="text"></div>
      <span class="more-close" @click="closeModal">&times;</span>
    </div>
  </div>
</template>

<script>

import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/vue";
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/scrollbar';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  name: "More",
  props: {
    swiper: {
      type: Boolean,
      required: false
    },
    visible: {
      type: Boolean,
      required: true,
    },
    title: String,
    img: {
      type: Object,
      default: () => ({}),
    },
    img_2: String,
    text: String,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, A11y],
    };
  },
  mounted() {
    if (this.visible) {
      this.disableScroll();
    }
  },
  watch: {
    visible(value) {
      if (value) {
        this.disableScroll();
      } else {
        this.enableScroll();
      }
    },
  },
  beforeUnmount() {
    this.enableScroll();
  },
  methods: {
    closeModal(event) {
      this.$emit("close");
    },
    disableScroll() {
      document.body.style.overflow = "hidden";
    },
    enableScroll() {
      document.body.style.overflow = "";
    },
  },
};
</script>

<style scoped lang="scss">
.more {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.more-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Change the opacity value to adjust the shading */
  z-index: -1;
}

.more-content {
  max-width: 600px;
  position: relative;
  background-color: white;
  padding: 30px 30px 30px 30px;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  overflow-y: auto; /* Add overflow-y property to enable vertical scroll */
  max-height: 80vh; /* Limit the maximum height of the modal content */
}

/* Add any additional styles for the modal content here */
.more-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
  color: #555;
}

.more__title {
  font-size: 36px;
  text-align: left;
  margin-bottom: 16px;
  margin-top: 30px;
}

input {
  border-radius: 8px;
  width: 100%;
  border: 1px solid #333333;
  padding: 16px 8px;
}
.more__input:not(:first-child) {
  margin-top: 8px;
}
.more__btn {
  margin-top: 16px;
  width: 100%;
}
.more__police {
  color: #333333;
  margin-top: 16px;
  text-align: center;
}
.more-open {
  overflow: hidden;
}
</style>
