<template>
  <HeaderBlock/>
  <TeaserBlock/>
  <VacancyBlock/>
  <BenefitsBlock/>
  <ApplicationBlock/>
  <ContactBlock/>
</template>

<script>
import HeaderBlock from './components/HeaderBlock.vue';
import TeaserBlock from './components/TeaserBlock.vue';
import VacancyBlock from './components/VacancyBlock.vue';
import BenefitsBlock from './components/BenefitsBlock.vue';
import ApplicationBlock from './components/ApplicationBlock.vue';
import ContactBlock from './components/ContactBlock.vue';

export default {
  name: 'App',
  components: {
    HeaderBlock,
    TeaserBlock,
    VacancyBlock,
    BenefitsBlock,
    ApplicationBlock,
    ContactBlock
  },
};
</script>
