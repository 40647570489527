<template>
  <section class="teaser">
    <div class="teaser__wrapper wrapper">
      <div class="teaser__block">
        <h1 class="teaser__title">
           Работа в Москве с проживанием и питанием 
        </h1>
        <p class="teaser__text">
           Вахта для женщин и мужчин в Москве с комфортным проживанием и вкусным питанием 
        </p>
        <button class="teaser__btn btn" @click="openModal">
          Заказать звонок
        </button>
      </div>
      <modal :visible="showModal" @close="closeModal" :title='title' :btn='btn'></modal>
    </div>
  </section>
</template>

<script>
import Modal from "../ui/ModalBlock.vue";

export default {
  name: "ParentComponent",
  components: {
    Modal,
  },
  data() {
    return {
      showModal: false,
      title:
        "Оставьте заявку, мы перезвоним и ответим на все ваши вопросы",
      btn: "Перезвоните мне",
    };
  },
  mounted() {
    
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>
