<template>
  <section id="vacancy" class="vacancy">
    <div class="vacancy__wrapper wrapper">
      <h2 class="vacancy__title">Вакансии</h2>
      <div class="vacancy__list">
        <div class="vacancy__card">
          <img src="@/assets/chertan.jpg" alt="" />
          <h3 class="vacancy__card-title">
            Комплектовщик на склад Яндекс-Маркет
          </h3>
          <p class="vacancy__card-text">Москва м. Чертановская</p>
          <p class="vacancy__card-text" style='font-weight:bold;color:#ed6929'>167 100 ₽ за 35 смен</p>
          <button class="vacancy__btn btn" @click="openModal('item_1')">
            Подробнее
          </button>
        </div>
        <div class="vacancy__card">
          <img src="@/assets/strogino.jpg" alt="" />
          <h3 class="vacancy__card-title">
            Сборщик на склад Яндекс-Маркет
          </h3>
          <p class="vacancy__card-text">Москва м. Строгино</p>
          <p class="vacancy__card-text" style='font-weight:bold;color:#ed6929'>179 000 ₽ за 35 смен</p>
          <button class="vacancy__btn btn" @click="openModal('item_2')">
            Подробнее
          </button>
        </div>
        <div class="vacancy__card">
          <img src="@/assets/dzerj.jpg" alt="" />
          <h3 class="vacancy__card-title">
            Упаковщик на склад Яндекс-Маркет
          </h3>
          <p class="vacancy__card-text">
            Дзержинский (20 минут на автобусе от метро Котельники)
          </p>
          <p class="vacancy__card-text" style='font-weight:bold;color:#ed6929'>175 000 ₽ за 35 смен</p>
          <button class="vacancy__btn btn" @click="openModal('item_3')">
            Подробнее
          </button>
        </div>
        <div class="vacancy__card">
          <img src="@/assets/sber.jpg" alt="" />
          <h3 class="vacancy__card-title">
            Комплектовщик на склад Сбер-Логистика
          </h3>
          <p class="vacancy__card-text">г. Пушкино</p>
          <p class="vacancy__card-text" style='font-weight:bold;color:#ed6929'>119 000 ₽ за 35 смен</p>
          <button class="vacancy__btn btn" @click="openModal('item_4')">
            Подробнее
          </button>
        </div>
      </div>
    </div>
    <MoreBlock
      :visible="selectedItemData !== null"
      :swiper="true"
      :title="selectedItemData ? selectedItemData.title : ''"
      :img="selectedItemData ? selectedItemData.imgs : {}"
      :text="selectedItemData ? selectedItemData.text : ''"
      @close="closeModal"
    />
  </section>
</template>

<script>
import MoreBlock from "@/ui/MoreBlock.vue";

export default {
  components: {
    MoreBlock,
  },
  data() {
    return {
      selectedItem: null,
      item_1: {
        imgs: {
          img_1: "/img/chertan.jpg",
          img_2: "/img/chertan_1.jpg",
          img_3: "/img/chertan_2.jpg",
          img_4: "/img/chertan_3.jpg",
          img_5: "/img/chertan_4.jpg",
        },
        title: "Чертановская",
        text: `<p>Комплектовщик (кладовщик) - сделка, в среднем 4800 ₽ за рабочую смену 11 часов  Небольшой сортировочный центр - смены с 12:00 до 00:00 (+ перерыв на обед и перекуры по 15 минут)   </p>

             <ul>
<li>Можно без опыта, всему обучаем (1ый день) </li>
<li>Вахта 35/45/60 смен - на выбор  </li>
<li>Бесплатное проживание в уютном хостеле в 7 минутах от склада </li>
<li>Можно работать БЕЗ проживания</li>
<li>Бесплатный комплексный обед  </li>
<li>Еженедельный аванс от 3000 ₽  </li>
<li>Выплата зарплата 2 раза в месяц 15 и 30 числа </li>
<li>Спецодежду предоставляем   </li>
</ul>

<p><strong>Обязанности:</strong></p>
<li>Приёмка/сортировка товара со сканером ТСД (сканеры новые)  </li>

<p><strong>
По обучению:</strong></p>
<p>После трудоустройства Вам приходит СМС от Яндекс-Маркет с вводными курсами в приложении, после прохождения курсов, на следующий день выходите на очное обучение на склад, длится оно 2 часа, затем 7 часов стажировка (не оплачивается), на следующий день выходите на рабочую смену. </p>
<p>1 и 2 смена оплачивается фиксированно по 3000₽ с 3 смены вы уже на сделке, как и все. </p>
<p>Наш опытный бригадир всегда рядом - в случае проблем всегда поможет.</p>`,
      },
      item_2: {
        imgs: {
          img_1: "/img/strogino.jpg",
          img_2: "/img/strogino_2.jpg",
          img_3: "/img/strogino_3.jpg",
          img_4: "/img/strogino_4.jpg",
          img_5: "/img/strogino_5.jpg",
        },
        title: "Строгино",
        text: `<p>Комплектовщик (кладовщик) - сделка, в среднем 5120 ₽ за рабочую смену 11 часов  </p>

<p>Небольшой сортировочный центр - смены с 17:00 до 05:00 (+ перерыв на обед и перекуры по 15 минут)  </p>
<ul>
<li>Можно без опыта, всему обучаем (1ый день) </li>
<li>Вахта 35/45/60 смен - на выбор  </li>
<li>Бесплатное проживание в уютном хостеле в 7 минутах от склада </li>
<li>Можно работать БЕЗ проживания </li>
<li>Бесплатный комплексный обед</li>
<li>Еженедельный аванс от 3000 ₽</li>
<li>Выплата зарплата 2 раза в месяц 15 и 30 числа</li>
<li>Спецодежду предоставляем</li>
</ul>
<p><strong>
Обязанности:</strong></p>
<p>Приёмка/сортировка товара со сканером ТСД (сканеры новые)  </p>
<p><strong>
По обучению:</strong></p>
<p>После трудоустройства Вам приходит СМС от Яндекс-Маркет с вводными курсами в приложении, после прохождения курсов, на следующий день выходите на очное обучение на склад, длится оно 2 часа, затем 7 часов стажировка (не оплачивается), на следующий день выходите на рабочую смену. </p>
<p>1 и 2 смена оплачивается фиксированно по 3000₽ с 3 смены вы уже на сделке, как и все. </p>
<p>Наш опытный бригадир всегда рядом - в случае проблем всегда поможет.</p>`,
      },
      item_3: {
        imgs: {
          img_1: "/img/dzerj.jpg",
          img_3: "/img/dzerj_2.jpg",
          img_4: "/img/dzerj_3.jpg",
          img_5: "/img/dzerj_4.jpg",
        },
        title: "Котельники",
        text: `<p>Комплектовщик (кладовщик) - сделка, в среднем 4900 ₽ за рабочую смену 11 часов  </p>

<p>Небольшой сортировочный центр - смены с 18:00 до 06:00 (+ перерыв на обед и перекуры по 15 минут)  </p>
<ul>
<li>Можно без опыта, всему обучаем (1ый день) </li>
<li>Вахта 35/45/60 смен - на выбор  </li>
<li>Бесплатное проживание в уютном хостеле в 7 минутах от склада </li>
<li>Можно работать БЕЗ проживания </li>
<li>Бесплатный комплексный обед</li>
<li>Еженедельный аванс от 3000 ₽</li>
<li>Выплата зарплата 2 раза в месяц 15 и 30 числа</li>
<li>Спецодежду предоставляем</li>
</ul>
<p><strong>
Обязанности:</strong></p>
<p>Приёмка/сортировка товара со сканером ТСД (сканеры новые)  </p>
<p><strong>
По обучению:</strong></p>
<p>После трудоустройства Вам приходит СМС от Яндекс-Маркет с вводными курсами в приложении, после прохождения курсов, на следующий день выходите на очное обучение на склад, длится оно 2 часа, затем 7 часов стажировка (не оплачивается), на следующий день выходите на рабочую смену. </p>
<p>1 и 2 смена оплачивается фиксированно по 3000₽ с 3 смены вы уже на сделке, как и все. </p>
<p>Наш опытный бригадир всегда рядом - в случае проблем всегда поможет.</p>`,
      },
      item_4: {
        imgs: {
          img_1: "/img/sber.jpg",
          img_2: "/img/sber_1.jpg",
          img_3: "/img/sber_2.jpg",
          img_4: "/img/sber_3.jpg",
        },
        title: "Сбер-Логистика",
        text: `<p>Комплектовщик - 3400 ₽ фиксированно за рабочую смену 11 часов (со 2й вахты 3500₽) </p>

              <p>Распределительный центр - смены с 09:00 до 21:00 и с 21:00 до 09:00 (день/ночь на выбор) + перерыв на обед и перекуры по 15 минут (3 раза за смену) </p>
<ul>
<li>Можно без опыта, обучаем на 1ой вашей смене (сразу идет оплата часов) </li>
<li>Вахта 15/35/45/60 смен - на выбор</li>
<li>Бесплатное проживание в общежитии в 15 минутах от склада (девушки у нас живут в квартирах рядом со складом) </li>
<li>Комплексные обеды</li>
<li>Еженедельный аванс от 3000₽ - Выплата зарплата 2 раза в месяц 15 и 30 числа</li>
<li>Спецодежду предоставляем  </li>
<li>Тут самая простая работа...  </li>
</ul>

<p><strong>Обязанности:</strong></p>
<li>Работа со сканером ТСД на разных операциях </li>
<li>Приёмка/сортировка товара со сканером ТСД </li>
<li>Сборка заказов  </li>
<li>Упаковка товара   </li>

<p><strong>
По обучению:</strong></p>
<p>Обучение происходит на вашей первой рабочей смене, длится оно 3 часа, во время обучения уже идёт оплата за работу.</p>`,
      },
    };
  },
  computed: {
    selectedItemData() {
      if (this.selectedItem) {
        return this[this.selectedItem];
      }
      return null;
    },
  },
  methods: {
    openModal(itemKey) {
      this.selectedItem = itemKey;
    },
    closeModal() {
      this.selectedItem = null;
    },
  },
};
</script>

<style lang='scss'>
  .more__text {
    p:not(:first-child) {
     margin-top: 8px;
    }

    ul {
      margin-top: 8px;

      li {
        list-style-type: disc;
        margin-left: 16px;
      }

      li:not(:first-child) {
        margin-top: 4px;
      }
    }
    strong {
      font-family: Raleway;
      font-weight: bold;
    }
  }
</style>

